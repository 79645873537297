import {
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BLink,
  BFormText,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BCardBody,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BCard,
  BInputGroupPrepend,
  BTable,
  BTab,
  BTabs,
  BFormRadio,
  BSidebar,
  BCardHeader,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormInvalidFeedback,
  BMedia,
  BMediaBody,
  BMediaAside,
  BFormFile,
  BFormTextarea,
  BOverlay,
  BPagination,
  BPaginationNav,
  BFormDatepicker,
  BFormTimepicker,
  BFormSelect,
  BAlert,
  BDropdown,
  BDropdownItem,
  BFormRadioGroup,
  BPopover,
  BCalendar,
  BCollapse,
  BTableLite,
  VBPopover,
  VBTooltip,
  VBToggle,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import InputMask from 'vue-input-mask'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { VTree } from 'vue-tree-halower'
import InitFileManager from '@/components/InitFileManager'
import draggable from 'vuedraggable'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

const GlobalMixin = {
  directives: {
    'b-popover': VBPopover,
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
    Ripple,
  },
  components: {
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BLink,
    BFormText,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardBody,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BCard,
    BInputGroupPrepend,
    BTable,
    BTab,
    BTabs,
    BCardHeader,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormRadio,
    BSidebar,
    BFormInvalidFeedback,
    BMedia,
    BMediaBody,
    BMediaAside,
    BFormFile,
    BFormTextarea,
    BOverlay,
    BPagination,
    BPaginationNav,
    BFormDatepicker,
    BFormTimepicker,
    BFormSelect,
    BAlert,
    BDropdown,
    BDropdownItem,
    BFormRadioGroup,
    BPopover,
    BCalendar,
    BCollapse,
    BTableLite,
    vSelect,
    VTree,
    InputMask,
    InitFileManager,
    draggable,
    Treeselect,
  },
  data() {
    return {
      statusOptions: [
        { label: this.$t('general.statuses.enabled'), value: 'enabled' },
        { label: this.$t('general.statuses.disabled'), value: 'disabled' },
      ],
      pageTypeOptions: [
        { label: this.$t('general.pageTypes.Simple'), value: 'Simple' },
        { label: this.$t('general.pageTypes.Html'), value: 'Html' },
        { label: this.$t('general.pageTypes.Home'), value: 'Home' },
      ],
      extensionTypes: [
        { label: this.$t('general.extensionTypes.TypeBanners'), value: 'TypeBanners' },
        { label: this.$t('general.extensionTypes.TypeHtml'), value: 'TypeHtml' },
        { label: this.$t('general.extensionTypes.TypeDevices'), value: 'TypeDevices' },
        { label: this.$t('general.extensionTypes.TypeContacts'), value: 'TypeContacts' },
        { label: this.$t('general.extensionTypes.TypeReviews'), value: 'TypeReviews' },
      ],
      extensionSubTypes: {
        TypeBanners: [
          { label: this.$t('general.extensionSubTypes.SubTypeInfo'), value: 'SubTypeInfo' },
          { label: this.$t('general.extensionSubTypes.SubTypeInformer'), value: 'SubTypeInformer' },
          { label: this.$t('general.extensionSubTypes.SubTypeProposal'), value: 'SubTypeProposal' },
          { label: this.$t('general.extensionSubTypes.SubTypeSlider'), value: 'SubTypeSlider' },
        ],
        TypeReviews: [
          { label: this.$t('general.extensionSubTypes.Default'), value: 'Default' },
        ],
        TypeDevices: [
          { label: this.$t('general.extensionSubTypes.Default'), value: 'Default' },
        ],
        TypeHtml: [
          { label: this.$t('general.extensionSubTypes.Default'), value: 'Default' },
        ],
        TypeContacts: [
          { label: this.$t('general.extensionSubTypes.Default'), value: 'Default' },
        ],
      },
    }
  },
  computed: {
    ...mapGetters({
      errors: 'validation/errors',
    }),
  },
  destroyed() {
    this.$store.dispatch('validation/clearErrors')
  },
  methods: {
    confirm(callback, options) {
      const data = {
        title: this.$t('notifications.warning'),
        text: this.$t('notifications.confirm.destroy.text'),
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: this.$t('notifications.confirm.destroy.buttons.cancel'),
        confirmButtonText: this.$t('notifications.confirm.destroy.buttons.yes'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
        ...options || {},
      }

      this.$swal(data).then(result => {
        if (result.isConfirmed) {
          callback(result)
        }
      })
    },
  },
}

export default GlobalMixin
